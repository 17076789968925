import api from './api'
export default {
    getAll(paginationQuery){
        return api.execute(`GET`,`/subuser`+paginationQuery)
    },
    CreatSubUser(data){
        return api.execute(`POST`,`/subuser`,data)
    },
    editSubUser(data){
        return api.execute(`PUT`,`/subuser`,data)
    },
    deleteSubUser(data){
        return api.execute(`DELETE`,`/subuser`,data)
    },
}