<template>
    <div>
        <Breadcrumbs main="" title="Team Members" />
        <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 mt-1">
            <div slot="with-padding">
              <b-row class="sectionShowDesktop">
                <b-col class="input-group cus-search-input">
                  <div class="form-group input-group-square">
                    <b-input-group >
                      <b-input-group-prepend >
                        <span class="custom-font-22 input-group-text search-bg border-right-0"><i class="icofont icofont-search-alt-2"></i></span>
                      </b-input-group-prepend>
                      <b-form-input
                        class="custom-font-22 border-left-0"
                        v-model="filter"
                        placeholder="Type to Search"
                        type="search"
                      ></b-form-input>
                    </b-input-group>
									</div>
                </b-col>
                <b-col class="col-md-8 col-12 d-flex justify-content-end">
                  <b-form-group
                    label-cols="6"
                    label="Per page"
                    class="text-right custom-font-20 text-secondary border border-dark pr-0 pl-1 mr-2 b-r-8 d-inline-block"
                  >
                  <b-form-select
                    class="custom-font-22 border-0 b-r-8"
                    v-model="perPage"
                    :options="pageOptions"
                    @change="getSubUsers"
                  ></b-form-select>
                  </b-form-group>
                  <div>
                    <button v-if="plan_details.plan_type == 1 || plan_details.plan_name == 'free'"
                    class="btn mr-2 custom-font-24" :class="btn_class" @click="OpenUpdatePlan()">+ Add User</button>
                    <button v-else class="btn mr-2 custom-font-24" :class="btn_class" @click="add_sub_user = true" >+ Add User</button>
                  </div>
                  <add-monitor-button class="hideMonitorBtn"></add-monitor-button>
                </b-col>
              </b-row>
              <b-row class="sectionShowMobile">
                <b-col class="cus-search-input">
                  <div class="input-group form-group input-group-square">
                    <b-input-group >
                      <b-input-group-prepend >
                        <span class="custom-font-22 input-group-text search-bg border-right-0"><i class="icofont icofont-search-alt-2"></i></span>
                      </b-input-group-prepend>
                      <b-form-input
                        class="custom-font-22 border-left-0 pr-5"
                        v-model="filter"
                        placeholder="Type to Search"
                        type="search"
                      ></b-form-input>
                    </b-input-group>
									</div>
                </b-col>
                <b-col class="mt-3">
                 <b-row class="d-flex justify-content-between">
                  <div class="col-6">
                    <b-form-group
                      label-cols="6"
                      label="Per page"
                      class="col-md-8 text-right custom-font-20 text-secondary border border-dark pr-0 pl-0 b-r-8 d-inline-block"
                    >
                    <b-form-select
                      class="custom-font-22 text-dark border-0 b-r-8"
                      v-model="perPage"
                      :options="pageOptions"
                      @change="getSubUsers"
                    ></b-form-select>
                    </b-form-group>
                  </div>
                  <div class="pr-2">
                    <button v-if="plan_details.plan_type == 1 || plan_details.plan_name == 'free'"
                    class="btn ml-5 custom-font-24 mr-2" :class="btn_class" @click="OpenUpdatePlan()">+ Add User</button>
                    <button v-else class="btn ml-5 custom-font-24 mr-2" :class="btn_class" @click="add_sub_user = true" >+ Add User</button>
                  </div>
                 </b-row>
                </b-col>
              </b-row>
              <div class="col-md-12" v-if="loading">
                <!-- <h6 class="sub-title mb-0 text-center">Loading...</h6> -->
                  <div class="loader-box">
                    <div class="loader-30"></div>
                  </div>
              </div> 
              <div v-else class="table-responsive datatable-vue text-left custom-font-18">
                <b-table
                  show-empty
                  stacked="md"
                  :items="items"
                  :filter="filter"
                  :fields="tablefields"
                  :per-page="perPage"
                  @filtered="onFiltered"
                >
                <template #cell(username)="data">
                  <span class="">{{ data.item.username }}</span>
                </template>
                <template #cell(email)="data">
                  <span class="">{{ data.item.email }}</span>
                </template>
                <!-- <template #cell(isactive)="data">
                    <div class="media-body icon-state switch-outline">
                        <label class="switch">
                        <input type="checkbox" :checked=data.item.isactive @change="statusUpdate(data.item,'isactive')"><span class="switch-state" :class="data.item.isactive?'bg-success':'bg-danger'"></span>
                        </label>
                    </div>                 
                </template> -->
                <template #cell(readOnly)="data">
                    <div class="media-body switch-outline">
                        <label class="switch">
                        <input type="checkbox" :checked=!data.item.readOnly @change="statusUpdate(data.item,'readOnly')"><span class="switch-state" :class="!data.item.readOnly?'bg-success':'bg-danger'"></span>
                        </label>
                    </div>                 
                </template>
                <template #cell(action)="data">
                    <feather class="text-danger" type="trash-2" title="Delete" @click="deleteSubUser(data.item._id)" style="cursor: pointer;"></feather>
                    <feather class="text-dark ml-2" type="edit" title="Edit" @click="editSubUser(data.item)" style="cursor: pointer;"></feather>         
                </template>
                </b-table>
              </div>

              <b-col md="12" class="mt-3 text-right p-0">
                <div class="pagination">
                  <a v-show="currentPage != 1" @click="getSubUsers('prev')">&laquo; Back</a>
                  <a class="active">{{currentPage}}</a>
                  <a v-show="items.length >= perPage" @click="getSubUsers('next')">Next &raquo;</a>
                </div>
              </b-col>
            </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-subuser" size="lg" @hidden="closeModal()" title="Team Member" class="theme-modal" v-model="add_sub_user" no-close-on-backdrop hide-footer>
        <div class="p-3">
            <form action="" @submit.prevent="AddUser()">
        <b-row>
            <b-col>
                <h6 class="text-primary">{{edit_sub_user?'Edit':'Add'}} your team members to keep them notified!</h6>
            </b-col>
        </b-row><hr>
        <b-row class="mb-2">
            <b-col md="3" class="font-weight-bold">
                User Name
                <span class="font-danger">*</span>
            </b-col>
            <b-col md="4">
                <b-form-input size="sm" type="text" name="name" v-model="input.username" :state="isNameEntered ? !fullname_err_text : null"  @input="usernameValidate" placeholder="Username" maxlength="50" required></b-form-input>
                <b-form-invalid-feedback v-if="isNameEntered">{{ fullname_err_text }}</b-form-invalid-feedback>
            </b-col>
        </b-row><hr>
        <b-row class="mb-2">
            <b-col md="3" class="font-weight-bold">
                Member Email
                <span class="font-danger">*</span>
            </b-col>
            <b-col md="4">
                <b-form-input size="sm" type="email" name="name" v-model="input.email" placeholder="Email" maxlength="100" :state="isEmailEntered ? !invalid_email_message : null" @input="validateEmail" required>
                </b-form-input>
                <b-form-invalid-feedback v-if="isEmailEntered">{{ invalid_email_message }}</b-form-invalid-feedback>
            </b-col>
        </b-row><hr>
        <b-row class="mb-2">
            <b-col md="3" class="font-weight-bold">
                Password
                <span class="font-danger">*</span>
            </b-col>
            <b-col md="4">
                <b-form-input size="sm" type="text" name="name" v-model="input.password" placeholder="Password" maxlength="25" :state="isPasswordEntered ? !invalid_password_message : null" @input="validatePassword" required>
                </b-form-input>
                <b-form-invalid-feedback v-if="isPasswordEntered">{{ invalid_password_message }}</b-form-invalid-feedback>
            </b-col>
        </b-row><hr>
        <!-- <b-row class="mb-2">
            <b-col md="3" class="font-weight-bold">
                Read Only Status
            </b-col>
            <b-col md="4">
                <input class="checkbox_animated" name="case_sensitive" type="checkbox" v-model="input.readOnly" />
            </b-col>
        </b-row><hr> -->
        <b-row class="mt-3 text-center">
            <b-col>
                <b-button type="submit" variant="primary" :disabled="disable_submit">{{edit_sub_user?'Edit User':'Add New User'}}</b-button>
            </b-col>
        </b-row>
    </form>
    </div>
    </b-modal>
 </div>
</template>


<script>
import SubUserController from "../../services/subUserController";
import AddMonitorButton from ".././components/addMonitorButton.vue";
import UserController from "../../services/userController";
export default {
    name: 'SubUser',
    components:{AddMonitorButton},
    data(){
        return{
            disable_submit:false,
            input:{username:''},
            add_sub_user:false,
            edit_sub_user: false,
            items:[],
            totalRows:0,
            filter:null,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 50],
            tablefields: [
                          { key: "username", label: "Name", sortable: true },
                          { key: "email", label: "Email", sortable: true },
                          { key: "readOnly", label: "Editor Access", sortable: false },
                          { key: "action", label: "Action", sortable: false },
                        ],
            loading: false,
            fullname_err_text:'Username required',
            plan_details:{},
            btn_class:'',
            isNameEntered: false,
            isEmailEntered: false,
            invalid_email_message: 'Email required',
            invalid_password_message: '',
            isPasswordEntered: false,
        }
    },
    mounted(){
      if(this.$root.$data.plan_details == undefined){
        this.getPlanInfo();
      } else {
        this.plan_details = this.$root.$data.plan_details
      }
      this.getSubUsers()

      let pathName = this.$route.name
      if (pathName === 'Dashboard' || pathName === 'Incidents' || pathName === 'Referral' || pathName === 'Profile') {
          this.btn_class = 'custom-btn-dark';
      } else {
          this.btn_class = 'add-monitor-btn-green';
      }
    },
    // computed:{
    //   validateUsername() {
    //     if(!this.Usernamevalidate()){
    //       return false;
    //     }
    //     return true;
    //   },
    // },
    methods:{
        async getSubUsers(txt){
            this.loading = true;
            this.items = []
            if(txt == 'next'){this.currentPage ++}
            if(txt == 'prev'){
              if(this.currentPage != 1){
              this.currentPage --
              }
            }
            let paginationQuery = '?currentPage='+this.currentPage+'&pageSize='+this.perPage
            
            let response = await SubUserController.getAll(paginationQuery)
            // if (response.result) {
                this.items = response.data;
                this.totalRows = this.items.length;
            // }
            this.loading = false;
        },
        validateEmail(email) {
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if(!regex.test(email)){
              this.$toasted.show('Invalid email', {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
              return false
            }
            return true;
          },
        async AddUser() {
          if(this.validateEmail(this.input.email) && this.usernameValidate(this.input.username) && this.validatePassword(this.input.password)){
            this.disable_submit = true
            var payload = {"username": this.input.username,"subUserEmail": this.input.email,"password": this.input.password,"readOnly": true}
            var response
            if(this.edit_sub_user){
                var p =  {"username": this.input.username,"password": this.input.password,"email": this.input.email}
                var e_payload = { "subUserId": this.input._id,"changePayload": p}
                response = await SubUserController.editSubUser(e_payload);
            }
            else{
                response = await SubUserController.CreatSubUser(payload);
            }
            
            if(response.result){
                this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
                this.add_sub_user = false;
                this.getSubUsers()
            }else{
                this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
            }
            this.disable_submit = false
        }
      },
      editSubUser(data){
          this.add_alers_modal = false
          this.add_sub_user = true
          this.input = data;
          this.edit_sub_user = true;

          if(this.edit_sub_user) {
            this.isNameEntered = true;
            this.fullname_err_text = '';  
            this.isEmailEntered = true;
            this.invalid_email_message = '';
          }
        },
     async deleteSubUser(id){
        if(confirm("Are you sure want to delete?")){
          let response = await SubUserController.deleteSubUser({"subUserId": id});
          if(response.result){
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
            this.getSubUsers()
          }
          else{
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 3000});
            this.getSubUsers()
          }
        }
      },
        closeModal(){
          this.input={}
          this.add_sub_user = false
          this.edit_sub_user = false;
          
          this.isNameEntered = null;
          this.fullname_err_text = '';  
          this.isEmailEntered = null;
          this.invalid_email_message = '';
          this.isPasswordEntered = null;
          this.invalid_password_message = '';

          this.getSubUsers();
        },
        onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async statusUpdate(data,txt){
        var payload
        var s = true
        var toastMessage = ''
        switch(txt){
            case 'isactive':
                if(data.isactive == true){
                        s = false
                    }
                    if (data.isactive == false) {
                    data.isactive = true;
                    } else {
                    data.isactive = false;
                    }
                    payload = {"subUserId": data._id, "changePayload": {"isactive":s}}
                break;
            case 'readOnly':
                    if(data.readOnly == true){
                        s = false
                    }
                    if (data.readOnly == false) {
                    data.readOnly = true;
                    } else {
                    data.readOnly = false;
                    }
                    payload = {"subUserId": data._id, "changePayload": {"readOnly":s}}

                    toastMessage = s ? "Sub-user editor access enabled" : "Sub-user editor access disabled";
                break;
        }
        let response = await SubUserController.editSubUser(payload)
        if(response.result){
            this.$toasted.show(toastMessage || response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
        }
        else{
          this.getSubUsers()
          this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
        }
      },
      openOtpModal(data){
        this.otp_data = []
        this.otp_data = data
      },
      copyUrl(txt){
        const el = document.createElement('textarea');
        el.addEventListener('focusin', e => e.stopPropagation());
        el.value = txt;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        this.$toasted.show('URL copied to clipboard.', {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
      },
      usernameValidate(username) {
        this.isNameEntered = true;
        username = username ? username : this.input.username;

        let regexp = /^(?!\s*$)[a-zA-Z0-9 ._-]+$/;

        if (username === "") {
          this.fullname_err_text = "Username required";
          return false;
        } else if (username.length > 50) {
          this.fullname_err_text = "Username cannot exceed 50 characters";
          return false;
        } else if (!regexp.test(username)) {
          this.fullname_err_text = "Username can only contain alphanumeric characters and spaces";
          return false;
        } else {
          this.fullname_err_text = "";
          return true;
        }
      },
      validateEmail (email) {
        this.isEmailEntered = true;
        email = email ? email : this.input.email;

        let regexp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if(regexp.test(email)){
          this.invalid_email_message = false;
          return true;
        } else {
          this.invalid_email_message = 'Please enter a valid email';
          return false;
        }
      },
      validatePassword(password) {
        this.isPasswordEntered = true;
        password = password ? password : this.input.password;

        if (password.trim() === '') {
          this.invalid_password_message = "Plase enter valid password";
          return false;
        } else {
          this.invalid_password_message = "";
          return true;
        }
      },
      OpenUpdatePlan(){
        this.$toasted.show('Please Upgrade your plan.', {theme: 'outline',position: "bottom-center", type: 'error', duration: 3000});
        var el = document.querySelector('#upgrade_btn');
        if(el){
          el.click()
        }    
      },
      async getPlanInfo() {
        let response = await UserController.getPlanInfo();
        if (response.result) {
          this.plan_details = response.message;
          this.$root.$data.plan_details = this.plan_details
        }
      },
    }
}

</script>

<style scoped>
.sectionShowMobile {
 display: none !important;
}


@media screen and (max-width: 768px) {
  .sectionShowMobile {
    display: block !important;
  }

  .sectionShowDesktop {
    display: none !important;
  }

}
</style>